<template>
  <div class="about">
    <h1>Über diese Liste</h1>
    <p>
      Als eines Abends der Auszug eines ewigen Beate-U Mitglieds anstand,
      fragten sich die verbliebenen Mitbewohner, was sie wohl erreichen müssten,
      um ebenfalls so respektable Passau Kenner werden zu können. bla
    </p>
  </div>
</template>
